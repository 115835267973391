import http from '@/utils/http';

//  分页
export function feedBackPage(formdata: any): any {
  return http.get(`/portal-resources/1/feedBack`, formdata);
}

//  修改
export function feedBackEdit(formdata: any): any {
  return http.put(`/portal-resources/1/feedBack`, formdata);
}

//  详情
export function feedBackDetails(id: string): any {
  return http.get(`/portal-resources/1/feedBack/${id}`);
}