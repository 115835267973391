










































































































































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/feedback';
import * as commonApi from '@/api/common';
import * as aes from '@/utils/aes/public';
import {
  dictListCommon,
  getDictLabel,
  dateStringToTimestamp,
  getDictLabelClass
} from '@/utils/index';
export default Vue.extend({
  name: 'certificationAuditList',
  data() {
    return {
      rules: {
        status: [
          {
            required: true,
            message: '处理结果不能为空',
            validateTrigger: 'change',
          },
        ],
      },
      loading: false,
      tableList: [],
      tableLoading: false,
      ModalForm: {},
      searchForm: {
        status: '0',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        {
          title: '序号',
          align: 'center',
          dataIndex: 'number',
          width: '80px',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '联系电话',
          dataIndex: 'telNumber',
          align: 'center',
        },
        {
          title: '联系人',
          dataIndex: 'linkName',
          align: 'center',
        },
        {
          title: '反馈软件',
          dataIndex: 'softWareType',
          scopedSlots: { customRender: 'softWareType' },
          align: 'center',
        },
        {
          title: '反馈类型',
          dataIndex: 'feedBackType',
          scopedSlots: { customRender: 'feedBackType' },
          align: 'center',
        },
        {
          title: '反馈标题/详情',
          dataIndex: 'info',
          width: '200px',
          scopedSlots: { customRender: 'info' },
          align: 'center',
        },
        {
          title: '反馈时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
          align: 'center',
        },
        {
          title: '处理时间',
          dataIndex: 'handleDate',
          scopedSlots: { customRender: 'handleDate' },
          align: 'center',
        },
        {
          title: '处理人',
          dataIndex: 'handleUserName',
          align: 'center',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'options',
          scopedSlots: { customRender: 'options' },
          align: 'center',
        },
      ],
      showModal: false,
      addrList: [],
      fileList: [
        // {
        //   uid: '-1',
        //   name: 'image.png',
        //   status: 'success',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-2',
        //   name: 'image.png',
        //   status: 'success',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
      ],
      previewVisible: false,
      previewImage: '',
      softWareTypeList: [],
      feedBackTypeList: [],
      statusList: [],
      statusListAll: [],
      optionType: '',
    };
  },
  mounted() {
    this.getList();
    this.getDictList();
  },
  methods: {
    moment,
    getDictLabel,
    getDictLabelClass,
    getDictList() {
      dictListCommon('soft_ware_type').then((res) => {
        this.softWareTypeList = res;
      });
      dictListCommon('feed_back_type').then((res) => {
        this.feedBackTypeList = res;
      });
      dictListCommon('feed_back_status').then((res) => {
        this.statusListAll = res;
      });
    },
    decryptData(res) {
      return JSON.parse(aes.decrypt(res.result));
    },
    isDisabled() {
      return ['see'].includes(this.optionType);
    },
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getList();
    },
    onSubmit() {
      (this.$refs.formFormRef as HTMLFormElement).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        api
          .feedBackEdit(this.ModalForm)
          .then((res) => {
            if (res.status == 200) {
              this.$message.success('操作成功！');
              this.showModal = false;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.searchForm = {
        status: '0',
      };
      this.pagination.current = 1;
      this.getList();
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    getList() {
      this.tableLoading = true;
      let postData = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        startDate: '',
        endDate: '',
        ...this.searchForm,
      };
      if (postData.handleDate && postData.handleDate.length) {
        postData.handleDateStart = postData.handleDate[0] + ' 00:00:00';
        postData.handleDateEnd = postData.handleDate[1] + ' 23:59:59';
      }
      if (postData.recDate && postData.recDate.length) {
        postData.recDateStart = dateStringToTimestamp(
          postData.recDate[0] + ' 00:00:00'
        );
        postData.recDateEnd = dateStringToTimestamp(
          postData.recDate[1] + ' 23:59:59'
        );
      }
      delete postData.handleDate;
      delete postData.recDate;

      api
        .feedBackPage(postData)
        .then((RES) => {
          if (RES.status === 200) {
            let res = RES.result;
            this.pagination.total = res.total;
            this.tableList = res.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async open(optionType, id) {
      // optionType transfer转单 handle处理
      // status 0待处理 1延期处理 2已转单 3已处理 4作废处理
      const RES = await api.feedBackDetails(id);
      const res = RES.result;
      this.fileList = [];
      if(res.fileUrl){
        let fileUrlList = res.fileUrl.split(',');
        fileUrlList.map((item, index) => {
          let info = {
            uid: index + 1,
            name: 'image.png',
            status: 'done',
            url: item,
          };
          this.fileList.push(info);
        });
      }
      this.ModalForm = res;
      this.ModalForm.statusOld = this.ModalForm.status;
      delete this.ModalForm.status;
      this.statusList = [];
      if (this.ModalForm.statusOld == '1') {
        // 延期处理
        this.statusListAll.map((item) => {
          if (item.entryValue == '4' || item.entryValue == '3' || item.entryValue == '2') {
            this.statusList.push(item);
          }
        });
      } else if (this.ModalForm.statusOld == '2') {
        // 已转单
        this.statusListAll.map((item) => {
          if (item.entryValue == '4' || item.entryValue == '3') {
            this.statusList.push(item);
          }
        });
      } else {
        this.statusList = JSON.parse(JSON.stringify(this.statusListAll));
      }
      this.statusList.map((item,index)=>{
        if (item.entryValue === '0') {
          this.statusList.splice(index,1)
        }
      })
      this.showModal = true;
      this.optionType = optionType;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    imageView(url) {
      this.previewImage = url;
      this.previewVisible = true;
    },
    confirmTransfer(item) {
      let params = item;
      params.status = '2';
      api.feedBackEdit(params).then((res) => {
        if (res.status == 200) {
          this.$message.success('转单成功！');
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
});
